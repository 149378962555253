<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'GroupbuyingsList',
              }"
            >
              團購管理
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'BlacklistList',
              }"
            >
              逾期未取名單
            </b-breadcrumb-item>
            <b-breadcrumb-item active>查看紀錄</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row mb-2">
        <div class="col-12 col-xl-8 d-flex items-center">
          <h4 class="font-weight-bold">查看紀錄</h4>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-12 col-xl-8 d-flex items-center">
          <div>
            <img :src="customer.avatar_url" style="width: 40px;">
            {{customer.name}} / {{customer.outer_code}}
          </div>
        </div>
      </div>

      <div
        class="d-flex flex-column flex-xl-row mt-4 mb-3 justify-content-between"
      >
        <div>
          <b-button variant="primary" @click="handleDelete" 
            v-if="checkPermission([consts.GROUPBUYING_BLACKLIST_REVOKE])"
            :disabled="!isBan"
            >解除停權</b-button
          >
        </div>
      </div>


      <b-table
        striped
        hover
        responsive
        :items="blacklists"
        :fields="fields"
        :busy="isLoading"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </b-table>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination
          class="separated"
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="groupbuyList"
          @change="(page) => fetchBlacklistHistories(page)"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import blacklistApi from "../../../apis/blacklist";
import PermissionChecker from '@/utils/PermissionChecker'
import * as consts from '@/consts';

export default {
  data() {
    return {
      isLoading: false,
      search: "",
      blacklists: [],
      isBan: true,
      customer: {
        outer_code: null,
        avatar_url: null,
        name: null,
      },
      activeBranch: null,
      fields: [
        {
          key: "group_name",
          label: "群組",
        },
        {
          key: "groupbuying_number",
          label: "團編號",
        },
        {
          key: "outer_order_number",
          label: "EC 訂單編號",
        },
        {
          key: "reason_code",
          label: "違規原因",
          formatter: (value) => {
            switch (value) {
                case 'unpaid':
                    return '未付款';
                case 'shipping_failed':
                    return '逾期未取貨';
                case 'unban':
                    return '復權';
                case 'ban':
                    return '停權';
                default:
                    return '';
            }
          },
        },
        {
          key: "created_at",
          label: "時間",
        },
      ],
      perPage: 10,
      currentPage: 1,
      total: 0,
      consts: consts,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      currentBranch: (state) => state.currentBranch,
    }),
    blacklistID() {
      return this.$route.params.blacklistID;
    },
  },
  mounted() {
    this.fetchBlacklistHistories();
  },
  methods: {
    async fetchBlacklistHistories(page) {
      try {
        this.isLoading = true;
        const { data } = await blacklistApi.getHistories(this.organization, {
          blacklist_id: this.$route.params.blacklistID,
          query: this.search,
          page: page || this.currentPage,
          per_page: 10,
        });
        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
        this.blacklists = data.data;
        if (data.blacklist !== null) {
          if (data.blacklist.ban_at == null) {
            this.isBan = false
          }
        }

        if (data.customer !== null) {
            this.customer = data.customer
        }
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },

    handleSearch() {
      this.fetchBlacklistHistories();
    },

    changePage() {
      this.fetchBlacklistHistories();
    },
    async handleDelete() {
      this.$swal
        .fire({
          type: "warning",
          title: "您確定要解除封鎖嗎？",
          html: `
            <div class="d-block">
              <div>解除後，該名會員即可參加團購，<br/>請您確認！</div>
            </div>`,
          confirmButtonText: "解除封鎖",
          cancelButtonText: "繼續封鎖",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              this.isLoading = true;
              this.isBan = false;
              await blacklistApi.unban(this.organization, this.$route.params.blacklistID);
              console.log("success");
            } catch (error) {
              console.log("");
            }
            this.isLoading = false;
          }
        });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
  },
};
</script>
