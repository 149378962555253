import https from "./https";

const blacklist = {
  getBlacklist(organization, params) {
    const searchParams = new URLSearchParams(params);
    return https.get(
      `admin/organizations/${organization.id}/blacklists?${searchParams}`
    );
  },
  getHistories(organization, params) {
    const searchParams = new URLSearchParams(params);
    return https.get(
      `admin/organizations/${organization.id}/blacklist-histories?${searchParams}`
    );
  },
  unban(organization, blacklistId) {
    return https.put(
      `admin/organizations/${organization.id}/blacklist-unban`, {
        blacklist_id: blacklistId,
      }
    );
  },
};

export default blacklist;
